import React from "react";
import Achievement from "../achievement"; //Sponsors and Partners
import Achievev3 from "../achievev3"; //as MeetPlayers  
import AuthorV2 from "../authorv2";
import Authorv3  from "../authorv3";//as WhyRead }
import Booksv2 from "../booksv2";
import Chapterv2 from "../chapterv2";
import ChapterPreview from "../chapter-preview";
import Contact from "../contact";
import Ctav4 from "../ctav4";
import Ctav5 from "../ctav5";
import FunFacts from "../fun-facts";
import Footer from "../global/footer";
import Header from "../global/header";
import Hero from "../hero";
import Pricing from "../pricing";
import Testimonial from "../testimonial";


const Version00 = ({header, footer}) => {
  const {menuv0} = footer;

  return (
    <>
     <Header header={header} />
      <Hero isBg="yes" />
      <Authorv3 isBg=""/>
      <Achievev3 isBg="yes"/>
      <Chapterv2 isBg="" />
      {/* <Pricing isBg="yes" />
      <Testimonial isBg="yes" /> 
      <Achievement isBg="yes" />
      <Contact isBg="" />*/}
      <AuthorV2 isBg="yes" />
      
      {/* <ChapterPreview isBg="" />
    
      <FunFacts isBg=""/>
      <Booksv2 isBg="yes" />
      <Ctav5 isBg=""/>
      <Ctav4 isBg="" /> */}
      <Footer  isBg="" menu={menuv0} />
    </>
  );
}

export default Version00;
