export const siteLogo = {
    logo: "assets/images/wonted-logo.png",  // Add your site logo here
    alt: "Amy Schembri"  // Add your site name here
};
export const socialLink = {
    facebook: "",
    twitter: "",
    linkedin: "",
    youtube: "",
    whatsapp: "",
    instagram: "https://www.instagram.com/amyschembri_author/",
    substack: "https://amyschembri.substack.com/",
};
export const mailchimpLink = {
    link: (process.env.REACT_APP_MAILCHAIMP) // Add your mailchimp list link here
};
export const contactFormAction = {
    link: (process.env.REACT_APP_CONTACTFORM)  // Add for link here from https://formspree.io
}
export const contactMap = {
    link: (process.env.REACT_APP_CONTACTMAP) // Add google map link from here: https://www.embedgooglemap.net/
}
