import React from "react";
import { Link } from "react-router-dom";
import achievementData from "../data/achievements.json";

const Achievement = ({ isBg }) => {
  const { achievements } = achievementData;

  var renderLink = (function () {
    return function (btnVal, btnIcon, btnUrl) {
      if (btnVal?.length > 0)
      {
      return (
        <Link to={btnUrl} target="_blank" className="link">
          {btnVal}
          <img
            className="img-fluid"
            src={btnIcon}
            alt="icon"
            width="20"
            height="20"
          />
        </Link>
      )
      }
    }
    

  })()

  return (
    <section
      id="achievements"
      className={`section-padding achievement ${
        isBg === "yes" ? "bg-one" : ""
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
               <h2 className="display-6">{achievements.subtitle}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row text-center mb-2">
            <span>{achievements.title}</span>
        </div>
        <div className="row row-cols-1 row-cols-md-2">
          {achievements?.awards?.map((data, i) => (
            <div
              key={data.id}
              className="m-15px-tb"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay={(i + 2) * 50}
            >
              <div className="achievement__item h-100 translateEffect1">
                <div className="row row-cols-2">
                  <div className="col mt-0">
                    <img
                      className="img-fluid"
                      src={data.image}
                      alt={data.title}
                    />
                  </div>
                  <div className="col mt-0">
                    <div className="achievement__content">
                      <div className="achievement__content__icon">
                        <img
                          className="img-fluid"
                          src={achievements.icon}
                          alt="icon"
                          width="80"
                        />
                      </div>
                      <h3>{data.title}</h3>
                      <p>{data.description}
                      </p>
                    {renderLink(data.linkBtn, data.btnIcon, data.linkUrl)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        
        <div className="row text-center mt-3 fs-6">
            <span>{achievements.footer}</span>
        </div>
      </div>
    </section>
    // <!-- ========== Achievement section end ========== -->
  );
};

export default Achievement;
